import LocalizedStrings from "react-localization";

const locales = {
  en: {
    //Local
    //urlApi: "http://localhost:8083/",

    //Servidor
    urlApi: "https://api2.producao.saveway.com.br/savetyre_morada/", 

    nomeSistema: "Savetyre - Gestão de Pneus",
    versaoSistema: "4.0.0",

    dataAtualizacao: "08/11/2023",
    atualizar: "Atualizar",
    aplicar: "Aplicar",
    carregando: "Carregando...",
    processando: "Processando...",
    cadastro_novo: "Novo Cadastro",
    contrato_novo: "Novo Contrato",
    sim: "Sim",
    nao: "Não",
    periodo: "Período",
    continuar: "Continuar",
    dica: "Regra",
    confirmar: "Confirmar",
    fechar: "Fechar",
    cancelar: "Cancelar",
    exclusao: "Exclusão",
    excluir: "Excluir",
    salvar: "Salvar",
    finalizar: "Finalizar",
    nome_tipo_unidade: "Nome",
    limpar: "Limpar",
    possui_unidade_pai: "Possui unidade pai",
    voltar: "Voltar",
    editar: "Editar",
    acoes: "Ações",
    msgErro: "Ocorreu um erro!",
    msgErroAPI: "Erro! Verifique endereço do banco!",
    selecione: "Selecione",
    selecionar: "Selecionar",
    msgCampoObrigatorio: "Campo Obrigatório",
    msgCamposInvalidos: "Preencha corretamente o formulário, existem campos inválidos",
    password_old: "Senha antiga incorreta.",
    denied_auth: "Acesso Negado.",
    erro_conection: "Verifique a conexão",
    data: "Data",
    hora: "Hora",
    login: "Login",
    nome: "Nome",
    nome_completo: "Nome Completo",
    relatorio: "Relatório",
    comentario: "Comentário",
    adicionar: "Adicionar",
    adicionar_veiculo: "Adicionar Veículo",
    remover_veiculo: "Remover Veículo",
    anexar: "Anexar",
    msgSucesso: "Salvo com sucesso!",
    upload: "Upload",
    global_search: "Pesquisa Global",
    total_registros: "Total de registros",
    initDate: "De",
    endDate: "Até",
    sobre: "Sobre",
    apagar: "Apagar",
    enviar: "Enviar",
    receber: "Receber",
    observacao: "Observação",
    dot: "DOT",
    foto: "Foto",
    fotos: "Fotos",

    inativar: "Inativar frota",
    alugar_frota: "Alugar Frota",
    finalizar_locacao: "Finalizar locação",
    frota_sera_inativada_o_que_fazer_com_os_pneus: "A frota será inativada, o que deverá ser feito com os pneus?",
    deseja_prosseguir_com_inativacao: "Deseja prosseguir com a inativação?",

    corrigir_km: "Corrigir km registro",
    corrigir_km_veiculo: "Corrigir km do veículo",
    // Login
    login_welcome: "Bem vindo, preencha o formulário.",
    login_welcome_alterar: "Troca de senha, favor atualizar senha.",
    login_nomeUsuario: "Nome do Usuário",
    login_email: "Usuário",
    login_senha: "Senha",
    login_logar: "Entrar",
    erro_logra: "Usuário ou Senha inválidos",
    Alterar_old: "Senha Antiga",
    Alterar_new: "Nova Senha",
    Alterar_reapet: "Confirmar Senha",
    Alterar_senha_title: "Alterar Senha.",
    login_rec_naoCorreta: "Senha incorreta.",
    login_rec_confirma: "As senhas não conferem.",
    login_rec_alterada: "Senha Alterada.",
    redefinit_senha: "Redefinir Senha",

    //Menu Superior
    menu_alterar_senha: "Alterar senha",

    //Dash
    dash_pneus_monitorados: "Pneus Monitorados",
    dash_pneus_estoque: "Em Estoque",
    dash_pneus_em_reforma: "Em Reforma/Conserto",
    dash_pneus_em_Analise: "Em Análise",
    dash_pneus_borracharia: "Em Borracharia",
    dash_pneus_uso: "Em Uso",
    dash_veiculos_monitorados: "Veículos Monitorados",
    dash_veiculos_pendencias: "Pendências",
    dash_veiculos_verificados: "% Veículos com Verificação Completa nos últimos ",
    dash_veiculos_verificados_parcial: "% Veículos com Verificação Parcial nos últimos ",
    dash_dias: " dias ",
    dash_pneus_nao_verificaos: "% de Pneus em Uso Não Verificados nos últimos 30 dias",
    dash_pneus_calib_desc: "% de Pneus com Calibração Desconhecida",
    dash_pneus_classificacao: "Classificação da Calibração dos pneus em uso",

    dash_pneus_fase: "Distribuição de Pneus por Fase de Vida",
    dash_pneus_marca: "Distribuição de Pneus por Marca",
    dash_pneus_descartados: "Pneus Descartados nos Últimos 12 Meses",
    dash_pneus_registrados: "Pneus Registrados nos Últimos 12 Meses",

    dash_pneus_registrados_por_unidade: "Pneus Registrados Por Unidade",
    dash_pneus_por_unidade: "Pneus Por Unidade",
    qtd_pneus_registrados_por_unidade: "Quantidade de pneus registrados por unidade.",
    qtd_pneus_em_estoque_na_unidade: "Quantidade de pneus em estoque na unidade.",

    //Filtros Consulta de veiculos
    consulta_veiculo_placa: "Placa",
    consulta_veiculo_uso: "Frotas Inativas",
    consulta_veiculo_composicao: "Composição",
    consulta_veiculo_tipo_uso: "Uso",
    consulta_veiculo_frotas_inativas_locadas: "Frotas inativas/alugadas",
    motivo_venda: "Motivo de Venda",

    //Relatório
    pneus_nao_verificados: "Relatório de Pneus Não Verificados",
    pneus_nao_verificados_30_dias: "Relatório de Pneus Em Uso Não Verificados há 30 dias",
    pneus_calib_desconhecida: "Relatório de Pneus Em Uso com Calibração Desconhecida",
    pneus_medidos_ult_30_dias: "Relatório de Pneus Medidos nos Últimos 30 Dias",
    plano_de_desgaste: "Relatório de Plano de Desgaste",
    pneus_calib_inadequada: "Relatório de Pneus Em Uso com Calibração Inadequada",
    relatorio_veiculos_nao_verificados: "Relatório de Veículos Não Verificados",
    relatorio_veiculos_verificados_parcialmente: "Relatório de Veículos Verificados Parcialmente",
    relatorio_registro_pneu: "Relatório de registro dos pneus",

    posicao: "Posição",
    ultima_operacao: "Ult. Operação",
    data_ult_opr: "Data Ult. Operação",
    data_prevista_desgaste: "Previsão Desgaste Mín.",
    status: "Status",
    quantidade: "Quantidade",
    groove_s1: "S1",
    groove_s2: "S2",
    groove_s3: "S3",
    groove_s4: "S4",
    km: "Km",
    km_anterior: "Km anterior",
    km_remaining: "Km Restante",
    pendencias: "Pendências",
    pendencia: "Pendência",
    pendencia_para: "Pendência para",
    motivo: "Motivo",
    km_minimo: "Km Total Mínimo",
    km_maximo: "Km Total Máximo",
    km_banda_minimo: "Km Banda Mínimo",
    km_banda_maximo: "Km Banda Máximo",
    cpk_min: "CPK Mínimo",
    cpk_max: "CPK Máximo",
    cpk_banda_min: "CPK Banda Mínimo",
    cpk_banda_max: "CPK Banda Máximo",
    tipo_eixo: "Tipo de Eixo",

    //Relatório Consulta Veiculos
    consulta_veiculo: "Consulta de Veículos",
    //Composição Veiculos
    veiculo_composicao: "Composição do Veículo",

    //Pendências
    motivo_cancelamento: "Motivo do Cancelamento",
    correcao: "Correção",

    //Estoque
    transferir: "Transferir",
    consulta_estoque: "Consulta de Estoque",
    unidade_registro: "Unidade de Registro",
    unidade_estoque: "Unidade de Estoque",
    ult_unidade: "Última Unidade",
    historico_transferencias: "Historico de Transferências",
    transferencia_estoque: "Transferir Pneu de Estoque",
    confirmacao_transferencia: "Confirmação de Transferência",
    msg_confirmacao_transferencia: "Você confirma a transferência de todos os pneus selecionados para a unidade selecionada?",

    //Atividade
    consulta_atividade: "Consulta Atividades",
    atividade: "Atividade",
    entidade: "Entidade",
    operador: "Operador",
    aprovador: "Aprovador",

    // Relatório de Operações
    relatorio_operacoes: "Relatório De Operações",
    resumo_por_entidade: "Resumo por Entidade",
    resumo_por_operacao: "Resumo por Operação",
    resumo_por_operador: "Resumo por Operador",

    operacao: "Operação",

    //Veiculos Update
    update_veiculo: "Editar Veículo",

    //Historico Veiculos
    veiculo_id: "Veículo ID",
    composicao: "Composição",
    board: "Placa",
    n_pneus: "N° de Pneus",
    n_estepes: "N° de Estepes",
    history_veiculo: "Histórico do Veículo",
    vehicle_operation_history: "Histórico da Operação do Veículo",
    vehicle_operation: "Operação de Veículo",
    date_operation: "Data da Operação",
    operator: "Operador",

    //Consulta Pneus
    //Tire Consultation
    tire_consult: "Consulta de Pneus",
    consult: "Consultar",

    //Filtros Consulta de Pneus
    //Tire Inquiry Filters
    tire_id: "Pneu ID",
    brand_fire: "Marca Fogo",
    fleet_id: "Frota ID",
    brand: "Marca",
    model: "Modelo",
    measure: "Medida",
    vida: "Vida",
    new_status: "Novo Status",

    //Filtros Secundários Consulta de Pneus
    //Secondary Filters Tire Inquiry
    rfid: "RFID",
    reforms: "Reformas",
    state: "Estado",
    calibration: "Calibração",
    pressure_recommended: "Pressão Recomendada",
    brand_band: "Marca de Banda",
    model_band: "Modelo de Banda",
    amount_grooves: "Quantidade de Sulcos",
    segment: "Segmento",
    kmMaxDia: "KM máx p/dia",
    use: "Uso",
    discard_reason: "Motivo de Descarte",
    all_grooves: "Todos os Sulcos",
    groove1: "Sulco 1 <=",
    groove2: "Sulco 2 <=",
    groove3: "Sulco 3 <=",
    groove4: "Sulco 4 <=",

    //Tire History
    history_tire: "Histórico do Pneu",
    km_total: "Km Total do registro",
    km_banda: "Km Banda do registro",
    novo_km: "Novo Km",

    //Editar Pneu
    update_tire: "Editar Pneu",

    // Auditoria Pneu
    tire_audit: "Auditoria Edições de Pneu",

    //Cadastros
    measure_tire: "Cadastrar Medida de Pneu",
    consultar_medida_pneu: "Consulta Medida de Pneu",
    editar_medida_pneu: "Editar Medida de Pneu",

    cad_pressao_recomendada: "Cadastrar Pressão Recomendada",
    consultar_pressao_recomendada: "Consulta Pressão Recomendada",
    editar_pressao_recomendada: "Editar Pressão Recomendada",

    marca_banda: "Cadastrar Marca de Banda",
    consultar_marca_banda: "Consulta Marca de Banda",
    editar_marca_banda: "Editar Marca de Banda",

    consulta_reformador: "Consulta Reformador",
    editar_reformador: "Editar Reformador",
    cadastrar_reformador: "Cadastrar Reformador",

    consulta_modelo_pneu: "Consulta Modelo de Pneu",
    editar_modelo_pneu: "Editar Modelo de Pneu",
    cadastrar_modelo_pneu: "Cadastrar Modelo de Pneu",

    consulta_marca_pneu: "Consulta Marca de Pneu",
    editar_marca_pneu: "Editar Marca de Pneu",
    cadastrar_marca_pneu: "Cadastrar Marca de Pneu",

    consulta_modelo_banda: "Consulta Modelo de Banda",
    editar_modelo_banda: "Editar Modelo de Banda",
    cadastrar_modelo_banda: "Cadastrar Modelo de Banda",

    consulta_segmento: "Consulta Segmento",
    editar_segmento: "Editar Segmento",
    cadastrar_segmento: "Cadastrar Segmento",

    consulta_tipo_uso: "Consulta Tipo de Uso",
    editar_tipo_uso: "Editar Tipo de Uso",
    cadastrar_tipo_uso: "Cadastrar Tipo de Uso",

    consulta_usuario: "Consulta Usuário",
    editar_usuario: "Editar Usuário",
    cadastrar_usuario: "Cadastrar Usuário",
    document: "Doc",
    tipo_documento: "Tipo de Documento",
    documento: "Documento",
    acesso_ti: "Acesso TI",

    consulta_perfil: "Consulta Perfil",
    editar_perfil: "Editar Perfil",
    cadastro_perfil: "Cadastrar Perfil",

    consulta_tabela_preco: "Consulta Tabela de Preço",
    editar_tabela_preco: "Editar Tabela de Preço",
    cadastro_tabela_preco: "Cadastrar Tabela de Preço",

    consulta_upload_custo: "Consulta de Uploads",
    editar_upload_custo: "Editar Upload",
    cadastro_upload_custo: "Cadastrar Upload",
    categoria_custo: "Categoria de Custo",
    editar_categoria_custo: "Editar Upload",
    referencia: "Referência",
    descricao: "Descrição",
    selecao_arquivo: "Selecione o Arquivo",
    valide_os_dados_importacao_upload_custo: "Valide os dados para continuar com a importação:",
    numero_concessionaria: "Número da Concessionária",
    tabela_preco: "Tabela de Preço",
    ano_mes: "Ano / Mês",
    nome_arquivo: "Nome do Arquivo",
    data_upload: "Data de Upload",
    selecao_de_dados_para_importacao: "Seleção de Dados para Importação",
    gerar_planilha: "Gerar Planilha",
    gerar_planilha_de_exemplo: "Gerar Planilha de Exemplo",
    escolher_arquivo: "Escolher Arquivo",
    erro_de_importacao_da_planilha: "Erro de Importação",
    encontrados_dados_invalidos: "Foram encontrados os seguintes erros na planilha enviada. Por favor, corrija-os antes de enviar a planilha novamente.",
    encontrados_dados_invalidos_cadastro_custo_aquisicao: "Foram encontrados os seguintes erros. Por favor, corrija-os e tente novamente.",
    data_referencia_ja_utilizada: "Já existe um registro com essa data de referência! Escolha outra data e tente novamente.",
    dados_aquisicao_ja_cadastrado: "Já existe um custo cadastrado com a data de referência enviada e para a(s) linha(s) %s. Apenas as linhas que não tem preço de aquisição cadastrado serão adicionadas.",
    contrato_ja_cadastrado: "Já existe um contrato cadastrado com os dados enviados na(s) linha(s) %s. Apenas as linhas que não tem contrato cadastrado serão adicionados.",
    deseja_alterar_data_do_upload: "Deseja continuar assim mesmo? Você pode alterar a data de referência para uma data mais recente para que o novo custo entre em vigor.",
    deseja_continuar: "Deseja continuar assim mesmo?",

    consulta_valor_carcaca: "Consulta Valor de Carcaça",
    cadastrar_valor_carcaca: "Cadastrar Valor de Carcaça",
    editar_valor_carcaca: "Editar Valor de Carcaça",

    consulta_valor_esperado_cpk_banda: "Consulta de Valor Esperado CPK Banda",
    cadastrar_valor_esperado_cpk_banda: "Cadastrar Valor Esperado CPK Banda",
    editar_valor_esperado_cpk_banda: "Editar Valor Esperado CPK Banda",
    estimar_cpk: "Estimar CPK",

    profundidade: "Profundidades(mm)",
    reformador: "Reformador",
    cnpj: "CNPJ",
    endereco: "Endereço",
    tipo_banda: "Tipo de Banda",
    tipo_veiculo: "Tipo de Veículo",
    tipo_pneu: "Tipo de Pneu",
    tipo_uso: "Tipo de Uso",
    eixo: "Eixo",
    btn_cadastrar: "Cadastrar",
    btn_avancar: "Avançar",
    ativo: "Ativo",
    off_road: "Off-Road",
    editar_medida: "Editar Medida",
    perfil: "Perfil",
    unidade: "Unidade",

    //Lote Reformas
    novo_lote: "Novo Lote",
    status_lote: "Status Lote",
    consulta_lote: "Consulta Lote",
    lote_id: "Lote ID",
    tipo_lote: "Tipo de Lote",
    editar_lote: "Editar Lote",
    compor_lote: "Compor Lote",
    data_abertura: "Data de Abertura",
    data_envio: "Data de Envio",
    caracteriza_reforma: "Caracterização Reformas",
    caracteriza_conserto: "Caracterização Consertos",
    posicao_conserto: "Posicão Conserto",
    filtros: "Filtros",
    sulco_max: "Sulco Max",
    receber_lote: "Receber Lote ",
    custo: "Custo",
    upload_Nf: "Nota Fiscal",
    status_pneu_lote: "Status Pneu Lote",
    status_pneu: "Status Pneu",

    //Tradução da tabela
    noRow: "Não há dados",
    search: "Procurar",
    searchPlaceholder: "Digite aqui o que procura...",
    download: "Download CSV",
    downloadTitle: "Download CSV",
    downloadDescription: "Efetue o download do arquivo em formato CSV",
    downloadSelectedRows: "Linhas selecionadas",
    downloadCurrentRows: "Linhas da página atual",
    downloadAllRows: "Todas linhas",
    display: "Exibir colunas",
    orderBy: "Ordenar por",
    drag: "Arrastar",
    paginationRows: "Linhas",
    paginationPage: "Página",

    marca_primeira_vida: "Marca 1ª Vida",
    uso_place_holder: "Selecione o uso",
    segmento_place_holder: "Selecione o segmento",
    measure_place_holder: "Selecione a medida",
    brand_place_holder: "Selecione a Marca",
    brand_band_place_holder: "Selecione a Marca de Banda",
    model_band_place_holder: "Selecione o Modelo de Banda",
    modelo_place_holder: "Selecione o Modelo",
    reforma_place_holder: "Selecione o Nº Reformas",
    reformador_place_holder: "Selecione o Reformador",
    status_place_holder: "Selecione o Status",
    tipo_banda_place_holder: "Selecione o Tipo de Banda",
    tipo_pneu_place_holder: "Selecione o Tipo de Pneu",
    posicao_conserto_place_holder: "Selecione a Posição do Conserto",
    multi_select_data_table_selected: "{0} Itens Selecionados",
    warning_itens_selected: "Itens Selecionados",
    estado_place_holder: "Selecione o Estado",
    discard_reason: "Motivo de Descarte",

    //Usuarios
    usuarios_id: "ID",
    usuarios_nome: "Nome",
    usuarios_sobrenome: "Sobrenome",
    usuarios_entidade: "Entidade",
    usuarios_cpf: "CPF",
    usuarios_nascimento: "Nascimento",
    usuarios_email: "E-mail",
    usuarios_level: "Perfil",
    usuarios_senha: "Senha",
    usuarios_confirmarSenha: "Confirmar Senha",
    usuarios_cadastradoSucesso: "Usuário cadastrado com sucesso!",
    usuarios_editadoSucesso: "Usuário editado com sucesso!",
    usuarios_excluidoSucesso: "Usuário removido com sucesso!",
    usuarios_novoUsuario: "Novo Usuário",
    usuarios_listUsuarios: "Lista de Usuários",
    usuarios_excluir: "Deseja exluir o Usuário ?",
    usuarios_cadastrarUsuario: "Cadastrar Usuário",
    usuarios_editarUsuarios: "Editar Usuario",
    usuarios_erroEmailInvalido: "Email Inválido",
    usuarios_erroConfirmarSenha: "Confirme a senha corretamente",
    usuarios_telefone: "Telefone",
    usuarios_celular: "Celular",
    usuarios_dendereco: "Endereço",
    usuariso_cidade: "Cidades",
    usuarios_estado: "Estados",
    usuarios_username: "Usuário",
    usuarios_cargo: "Cargo",
    msgErroUser: "'Usuário já existente!'",

    //TempoOperacao
    tempoOperacao_id: "Id",
    tempoOperacao_operacao_id: "Operacao Id",
    tempoOperacao_tempo: "Tempo",
    tempoOperacao_dt_ini: "Data Inicio",
    tempoOperacao_dt_Fim: "Data Fim",
    tempoOperacaoConsulta: "Consulta Tempo De Operação",
    tempoOperacaoHistorico: "Histórico Tempo De Operação",

    //ValorHora
    valorHoraConsulta: "Consulta Valor Hora",
    ValorHora_Cliente_Id: "Cliente Id",

    //Cliente
    cliente: "Consulta Cliente",
    cadastrar_cliente: "Cadastrar Cliente",
    editar_cliente: "Editar Cliente",

    //Contrato
    contrato: "Consulta Contrato",
    cadastrar_contrato: "Cadastrar Contrato",
    editar_contrato: "Editar Contrato",
    data_inicial: "Data Inicial",
    data_final: "Data Final",
    numero_contrato: "Número Contrato",
    unidade: "Unidade",
    cliente: "Cliente",


    //Cpk
    Cpk_id: "Id",
    Cpk_Consulta: "Consulta de CPK",
    Cpk_Custo_Por_KM: "Custo por Km",
    Cpk_Vida: "Vida",
    Cpk_Qtd_Reformas: "Quantidade de reformas",
    Cpk_histograma: "Histograma",

    //Simulação CPK
    Cpk_Simulacao: "Simulação de CPK",
    Cpk_Vida: "Vida",
    Cpk_Custo_Reforma: "Custo de Reforma (R$)",
    Cpk_Custo_Conserto_Ext: "Custo conserto externo (R$)",
    Cpk_Custo_Conserto_Int: "Custo conserto interno (R$)",
    Cpk_Custo_Mao_De_Obra: "Custo mão-de-obra (R$)",
    Cpk_Estimativa_Km: "Estimativa Km",
    Cpk_Cpk_Estimado: "CPK Estimado (R$/Km)",

    // Estimativa CPK
    Cpk_Estimativa_Historico: "Histórico de Estimativas de CPK",

    //Gestão de desempenho
    gest_desempenho_performance_pneus: "Performance dos pneus por marca, segmento e tipo de Uso.",
    gest_desempenho_demo_grafic:
      "O gráfico demonstra quanto o grupo de pneus percorre (km) para desgastar 1mm da borracha.",
    gest_desempenho_contemp_grafc:
      "Este gráfico contempla apenas pneus primeira vida (0 reformas).",
    gest_desemp_contemp_reforms: "Este gráfico contempla apenas pneus reformados.",
    gest_desempenho_obs_tipo: "Obs: Tipo de Uso = Tipo de Operação.",
    gest_desempenho_performance_pneus_mod:
      "Performance dos pneus por marca, medida, segmento e tipo de Uso.",
    gest_desempenho_performance_pneus_medida:
      "Performance dos pneus por marca, modelo, segmento, tipo de Uso",
    gest_desempenho_performance_pneu_mod_banda:
      "Performace dos pneus por marca de banda, modelo de banda, segmento, uso",

    dash_mm_km_marca: "Km/mm Médio por Marca (Primeira Vida)",
    dash_mm_km_modelo: "Km/mm Médio por Modelo (Primeira Vida)",
    dash_mm_km_marca_banda: "Km/mm Médio por Marca de Banda",
    dash_mm_km_modelo_banda: "Km/mm Médio por Modelo de Banda",
    dash_mm_km_medida: "Km/mm Médio por Medida (Primeira vida)",

    //Gestão de ativos
    gest_ativos_quant_descart_12meses:
      "Quantidade de pneus descartados por mês, considerando os últimos 12 meses a partir da data atual.",
    gest_ativos_obs_tipo: "Obs: Tipo de Uso = Tipo de Operação",
    gest_ativos_pneus_chips:
      "Quantidade de pneus com chips instalados e registrados por mês no sistema, considerando os últimos 12 meses.",
    gest_ativos_pneus_verificados: "% de Pneus em Uso Verificados ",
    gest_ativos_pneus_medidos: "% de Pneus Medidos ",
    gest_ativos_pneus_uso_com_calib: "% de Pneus em Uso com pressão de calibragem ",
    gest_ativos_pneus_uso_pres_calib: "% de Pneus em Uso que possuem pressão de calibragem ",
    gest_ativos_pneus_uso_pres_calib_press:
      "independente da classificação da pressão e considerando ",
    gest_ativos_pneus_uso_pres_calib_operacoes: "todas as operações  que realizam medição. ",
    gest_ativos_pneus_com_verif: "% de Pneus em Uso com ",
    gest_ativos_percent: "Percentual de pneus em uso que não sofreram ",
    gest_ativos_percent_veicle: "Percentual de veículos com ",
    gest_ativos_ver_complet: "Verificação Completa ",
    gest_ativos_medicao: "Medição de pressão e sulcos ",
    gest_ativos_ver_parcial: "Verificação Parcial ",
    gest_ativos_ultimos30: "nos últimos ",
    gest_ativos_classific_recomens: "classificação de calibração conforme pressão recomendada",
    gest_ativos_quant_por_uso: "Quantidade de pneus em uso por ",
    gest_ativos_dist_pneu_marca: "Percentual de pneus monitorados por ",
    gest_ativos_porcent_marca:
      "Marcas que representarem percentual menor que 1% serão acrescentadas na categoria Outros.",

    dias_restantes: "Dias Restantes",
    dot: "DOT",
    preco_aquisicao: "Preço de Aquisição",
    custo_aquisicao: "Custo de Aquisição",
    editar_custo_aquisicao: "Editar Custo de Aquisição",
    cadastrar_custo_aquisicao: "Cadastrar Custo de Aquisição",

    cadastro_valor_hora: "Cadastro Valor Hora",
    edicao_valor_hora: "Edição Valor Hora",
    historico_valor_hora: "Histórico Valor Hora",
    valor_hora: "Valor Hora",
    valor_conserto_rapido: "Valor Conserto Rápido",

    consulta_unidade: "Consulta de Unidade",
    cadastro_unidade: "Cadastro de Unidade",
    edicao_unidade: "Edição de Unidade",

    n_conta: "Nº Conta",
    cidade: "Cidade",
    estado: "Estado",

    id: "ID",
    possui_matriz: "Possui Matriz?",

    consulta_tipo_unidade: "Consulta de Tipo de Unidade",
    cadastro_tipo_unidade: "Cadastro de Tipo de Unidade",
    edicao_tipo_unidade: "Edição de Tipo de Unidade",

    tipo_unidade: "Tipo de Unidade",
    unidade_pai: "Unidade Pai",
    unidade_filho: "Unidade Filho",

    prosseguir_cadastro_tipo_unidade: "Deseja prosseguir com o cadastro de tipo de unidade?",
    prosseguir_edicao_tipo_unidade: "Deseja prosseguir com a edição de tipo de unidade?",

    editar_sulco_minimo: "Editar Sulco Mínimo",
    consulta_sulco_minimo: "Consulta Sulco Mínimo",
    cadastrar_sulco_minimo: "Cadastrar Sulco Mínimo",
    sulco_minimo: "Sulco Mínimo"
  },
};
const locale = new LocalizedStrings(locales);

export { locale };
